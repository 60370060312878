import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import styleLanding from './style/styleLanding.css';

import Success from '../pages/Success.js';
import NotFound from '../pages/Notfound.js';
import Login from '../pages/Login.js';
import Register from '../pages/Register.js';
import Loading from '../pages/Loading.js';

const LogoTiendaNube = 'https://res.cloudinary.com/hbrrdozyj/image/upload/v1634256559/logotipo-night-blue_ds729g.png';
const App = () => (
  <div>
    <header>
      <img 
       src={LogoTiendaNube}
       width="264"
       />
    </header>
    <BrowserRouter>
      <Switch>
        <Route exact path="/success" component={Success} />
        <Route exact path="/init" component={NotFound} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/loading" component={Loading} />
        <Route component={Loading} />
      </Switch>
    </BrowserRouter>
  </div>
);

export default App;
