import React,{useState,useEffect} from 'react';


const BtnAnimation = ({title,BooleanDisabled,message}) => {
    const [loading,setLoading] = useState();
    const [cleanMessage,setCleanMessage] = useState();
    
    const animateBtn = () => {
        setLoading('loading'); 
        resetAnimation()
    }
    

    const resetAnimation = () => {
        setTimeout(() => setCleanMessage(''),4000);
        setTimeout(() => setLoading(),4000)  
    }

    useEffect(()=>{
        setCleanMessage(message)
    },[message]);


    return (   
        <div className={`button-wrapper ${loading} ${cleanMessage}`}>
            <button 
            disabled={BooleanDisabled}
            onClick={()=>animateBtn()}
            className="btn-login btn-cliengo send">{title}</button>
            <div className="loading-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div> 
    );
};

export default BtnAnimation;
