import React, { useState } from 'react';

const Context = React.createContext();
const { Provider } = Context;

const dataStore = {
  email: '',
  access_token: '',
  storeId: '',
  phone: '',
  url: ''
};

const StateProvider = ({ children }) => {
  const [data, setData] = useState(dataStore);
  console.log("🚀 ~ file: store.js ~ line 16 ~ StateProvider ~ data", data)
  return (
    <Provider
      value={{
        data,
        setData
      }}
    >
      {children}
    </Provider>
  );
};

export { Context, StateProvider };
