import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router-dom';

const Loading = () => {
  const [logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const { jwt } = Cookies.get();
    if (jwt) setLogged(true);
    setLoading(false);
  }, []);
  return (
    <>
      {loading ? (
        <div className="loading">
          <img
            src="https://res.cloudinary.com/hbrrdozyj/image/upload/v1591102869/logo_cliengo_exygpb.png"
            width="179"
            height="179"
          />
        </div>
      ) : (
        <>{logged && !loading ? <Redirect to="/success" /> : <Redirect to="/init" />}</>
      )}
    </>
  );
};

export default Loading;
