import React, { useEffect, useContext } from 'react';
import logoCliengo from '../image/logo-cliengo.svg';
import { analytics } from '../utils/analytics';
import { Context, StateProvider } from '../utils/store';

const NotFound = () => {
  const value = useContext(Context);
  const analyticsEventEcommerce = (actionName) => {
    analytics.track('tiendanube', {
      action: actionName
    });
    const route = (actionName === 'login_tn')?'login':'register'
    window.location = `https://tiendanube.cliengo.com/${route}`;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {
      access_token: urlParams.get('access_token'),
      storeId: urlParams.get('storeId'),
      phone: urlParams.get('phone'),
      url: urlParams.get('url'),
      email: urlParams.get('email')
    };
    localStorage.setItem('info', JSON.stringify(params));
  }, []);

  return (
    <StateProvider>
      <div className="card notfound">
        <img src={logoCliengo} width="195" />
        <p>
          Cliengo ayuda a escalar tu proceso comercial, te acompaña en la gestión, atención al cliente y logística de
          tus productos.
        </p>
        <div className="btn-group">
          <button onClick={() => analyticsEventEcommerce('login_tn')} className="btn-login">
            Ingresar a Cliengo
          </button>
          <button onClick={() => analyticsEventEcommerce('register_tn')} className="btn-register">
            Registrarse
          </button>
        </div>
      </div>
    </StateProvider>
  );
};

export default NotFound;
