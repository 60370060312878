import React,{useState,useEffect} from 'react';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import styles from '../components/InputPassword/Form.module.css'
import eye from '../image/eye.svg';
import eyeSlash from '../image/eye-slash.svg';

import BtnAnimation from '../components/ButtonAnimation/index';

import {analytics} from '../utils/analytics';

import logoCliengo from '../image/logo-cliengo.svg';

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Ingresa un e-mail con formato válido.').required('Campo obligatorio!'),
  password: Yup.string() 
    .min(8, 'Ingresa una clave de al menos 8 caracteres.')
    .required('Campo obligatorio!')
});


const Login = () => {
  const params = JSON.parse(localStorage.getItem('info'));
  const [showPassword,setShowPassword] = useState(false);
  const [message,setMessage] = useState();

  const handleSubmit = (values) => {
   

   const {email,password} = values;

    analytics.track('tiendanube', {
      action: 'loggin_in'
    });

    const endPoint = 'https://app.cliengo.com/login';
    axios.post(endPoint, { username: email, password:password}, { withCredentials: true })
    .then(function (response) {
      setMessage('success');
      window.location = `https://api.cliengo.com/1.0/integration/tiendanube/syncUpToCliengo?accessToken=${params.access_token}&storeId=${params.storeId}`;

    })
    .catch(function (error) {
      // handle error
      console.error(error);
      setMessage('failure');
    })
    setMessage('');
  }
  useEffect(()=>{
  },[message])

  const handleAnalytics = (actionName) => {
    analytics.track('tiendanube', {
      action: actionName
    });
    setTimeout(redirect(actionName),1000)
  }

  const redirect = (actionName)=> {
    (actionName === 'new_account') ? 
    window.location.href = 'https://tiendanube.cliengo.com/register' 
    : 
    window.location.href = 'https://www.login.cliengo.com/emailSend';    
  }

  return (
    <div className="card login">
      <img src={logoCliengo} width="195" />
      <h2>Tu sitio</h2>
      <p>
        {params.url}
      </p>
      <Formik
       initialValues={{
         password: '',
         email: params?.email?params.email:'',
       }}
       validationSchema={SignupSchema}
       onSubmit={(values) => handleSubmit(values)}
     >
    {({ errors, touched }) => (
          
         <Form>
          
           <div className="form-control">
              <label>Cuenta</label>
              <Field 
              name="email" 
              type="email"
              placeholder="mail@cliengo.com"
              className={`${styles.form__container__field} ${errors.email && touched.email ? styles.error : ''}`}
              />
              {errors.email && touched.email ? 
              <div className={styles.form__div__message_error}>
                <i className="exclamation-error icon-exclamation-triangle" />
                {errors.email}
              </div> : null}
           </div>
           <div className="form-control">
              <label>Contraseña</label>
              <div className={styles.form__container__password_container}>
                <Field 
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="********" 
                className={`${styles.form__container__field} ${errors.password && touched.password ? styles.error : ''}`}
                />
                {(errors.password && touched.password) ?
                  '' :
                  <img
                  src={!showPassword ? eye : eyeSlash}
                  onClick={() => setShowPassword(!showPassword)}
                  className={styles.form__container__field__toggle}
                  />
                }
              </div>
              {errors.password && touched.password ? 
              <div className={styles.form__div__message_error}>
                <i className="exclamation-error icon-exclamation-triangle" />
                {errors.password}
              </div> : null}
            </div>

            <div className="forgoted"><a onClick={() => handleAnalytics('forgot_password')} >¿Olvidaste tu contraseña?</a></div>
            <BtnAnimation 
              message={message}
              BooleanDisabled={(errors.password || errors.email)} 
              title={"Ingresar"} 
              callback={handleSubmit}
            />
            <div className="donthave">¿No tienes cuenta? <a onClick={() => handleAnalytics('new_account')} >Crear una cuenta</a></div>
         </Form>
       )}
     </Formik>
     
    </div>
  );
};

export default Login;
