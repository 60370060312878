import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import axios from 'axios';

import styles from '../components/InputPassword/Form.module.css';
import eye from '../image/eye.svg';
import eyeSlash from '../image/eye-slash.svg';

import logoCliengo from '../image/logo-cliengo.svg';
import InputPassword from '../components/InputPassword/index';
import BtnAnimation from '../components/ButtonAnimation/index';

import { analytics } from '../utils/analytics';

const SignupSchema = Yup.object().shape({
  password: Yup.string().min(8, 'Ingresa una clave de al menos 8 caracteres.').required('Campo obligatorio!')
});

const Register = () => {
  const params = JSON.parse(localStorage.getItem('info'));
  const store_id = '';
  const [showPassword, setShowPassword] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);

  const [message, setMessage] = useState();
  useEffect(() => {}, [message]);

  const handleSubmit = (values) => {
    const endPoint = 'https://app.cliengo.com/signup';
    const body = {
      username: params.url,
      accountName: params.email,
      email: params.email,
      phone: params.phone,
      password: values.password,
      channel: 'tiendanube',
      ecommercePlatform: 'TIENDA_NUBE',
      labs: 'ecommerce,ecom_tn_beta,nexted_answers'
    };

    axios
      .post(endPoint, body, { withCredentials: true })
      .then(function (response) {
        console.log('🚀 ~ file: Register.js ~ line 48 ~ response', response);
        analytics.track('tiendanube', {
          action: 'register_cta',
          label: 'success'
        });
        setMessage('success');
        window.location = `https://api.cliengo.com/1.0/integration/tiendanube/syncUpToCliengo?accessToken=${params.access_token}&storeId=${params.storeId}&websiteId=${response.data.chatConfig.websiteId}`;
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        analytics.track('tiendanube', {
          action: 'register_cta',
          label: 'error'
        });
        setMessage('failure');
      });
  };
  return (
    <div className="card register">
      <img src={logoCliengo} width="195" />
      <p className="details">
        Crea tu usuario en Cliengo en un solo paso y comenza a automatizar las dudas de tus clientes.
      </p>
      <h2>Tu sitio</h2>
      <p className="email">{params.email}</p>
      <p className="site">{params.url}</p>
      <Formik
        initialValues={{
          password: ''
        }}
        validationSchema={SignupSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched }) => (
          <Form>
            <div className={`${styles.form__div__box_error} ${!duplicateEmail && styles.div__message_error}`}>
              <i className="exclamation-error icon-exclamation-triangle" />
              <p>Parece que ya tienes una cuenta con este email, comunícate con tu administrador.</p>
            </div>
            <div className="form-control">
              <label>Contraseña</label>
              <div className={styles.form__container__password_container}>
                <Field
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="********"
                  className={`${styles.form__container__field} ${
                    errors.password && touched.password ? styles.error : ''
                  }`}
                />
                {!errors.password ? (
                  <img
                    src={!showPassword ? eye : eyeSlash}
                    onClick={() => setShowPassword(!showPassword)}
                    className={styles.form__container__field__toggle}
                  />
                ) : null}
              </div>
              <div className="help-block">8 caracteres mínimo</div>
              {errors.password && touched.password ? (
                <div className={styles.form__div__message_error}>
                  <i className="exclamation-error icon-exclamation-triangle" />
                  {errors.password}
                </div>
              ) : null}
            </div>
            <BtnAnimation
              message={message}
              BooleanDisabled={errors.password || errors.email}
              title={'Registrarse'}
              callback={handleSubmit}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Register;
