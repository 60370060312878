import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import runtimeEnv from '@mars/heroku-js-runtime-env';

import logoCliengo from '../image/logo-cliengo.svg';
import { Button } from 'cliengo-ui';
const env = runtimeEnv();

const Success = () => {

  const handleSubmit = () => {
    window.location = `https://crm.cliengo.com/`;
  };

    return (
      <div>
        <div className="card">
          <img src={logoCliengo} width="195" />
          <div className="success-msg">
            <div className="success-msg__symbol">
              <span className="icon-light_check"></span>
            </div>
          </div>
          <h2>¡Tu cuenta ha sido vinculada con éxito!</h2>
          <Button
            style={{ padding: '0 18px', marginBottom: '11px', height: 'auto !important' }}
            callback={handleSubmit}
            title="Continuar"
          />
        </div>
      </div>
    );

}

export default Success;
