import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';

export const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    segmentPlugin({
      writeKey: 'xkBB8xARRQrsTusb2aKEphrb8qQeRoRj'
    })
  ]
})